import './header.css'
import { useNavigate } from 'react-router-dom';
function Header() {
  
  const navigate = useNavigate();



  function logout() {
    localStorage.clear();
    // window.location.href = '/';
    navigate('/');
}
  return (
    <div className='head'>
        <div><img height='100px' src="https://images.squarespace-cdn.com/content/v1/5ced4f076fe3500001faed14/1571720301509-TKJGGLAMBWSHLQA5M88W/pear+bio+logo+6.PNG?format=1500w" alt="logo"/> </div>
        <button className="logout-btn" onClick={logout}>Logout</button>
    </div>
  )
}

export default Header

