import React from 'react';
import { useState } from 'react';
import axios from 'axios';
import {baseURL} from '../baseUrl';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';

function Register(){
    const navigate = useNavigate();
    const registerUrl = `${baseURL}/auth/register`
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [invalidRegister, setinvalidRegister] = useState(false);
    const [backendMessage, setbackendMessage] = useState('');
    let statCode

    function handleUsername(e){
        setUsername(e.target.value)
    }
    function handleEmail(e){
        setEmail(e.target.value)
    }
    function handlePassword(e){
        setPassword(e.target.value)
    }

    function handleRegister(e) {
        e.preventDefault();
        let payload = {
            username,
            email,
            password
        }
        console.log(payload)
        // registerUser(payload)
        signUp(payload)
        resetRegisterForm()
    }
    function resetRegisterForm(){
        setUsername('')
        setEmail('')
        setPassword('')
    }

    // function registerUser(payload) {
    //     axios
    //       .post(registerUrl, payload)
    //       .then((response) => {
    //         // setPost(response.data);
    //         console.log(response)
    //       });
    //   }

      function signUp(payload){
        setinvalidRegister(false)
        setbackendMessage('')
        let formData = new FormData();
        formData.append("username", payload.username);
        formData.append("email", payload.email);
        formData.append("password", payload.password);

        fetch(registerUrl, {
        method: 'POST',
        body: formData
      }).then(response => {
        console.log(response)
        statCode = response.status
        return response.json()
      }).then(result => {
        console.log('success:', result);
        console.log(statCode)
        if(statCode == 400 || statCode == 409){
            console.log(result.error)
            setinvalidRegister(true)
            setbackendMessage(result.error)
        }else if(statCode == 201){
            localStorage.setItem('user_details', JSON.stringify(result.user.username))
            regAlert(result.user.username)
        }else{
            setinvalidRegister(true)
            setbackendMessage('Something went wrong, refresh and try again')
        }
      })
      }

      const regAlert = (username) => {
        Swal.fire({  
            title: `Welcome! ${username}`, 
            text: 'Your registration was succcessful.',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000
        }).then((result) => {
            navigate('/upload')
            // window.location.reload()
          })
    }
    return (
        
            <form action="" className="sign-up-form">
            <h2 className="title">Register</h2>
            {invalidRegister?<p className="text-danger">{backendMessage}</p> :''}
            <div className="input-field">
                <i className="fas fa-user"></i>
                <input type="text" name="usuario" autoComplete="username" placeholder="Username" required="yes" value={username} onChange={handleUsername}/>
            </div>
            <div className="input-field">
                <i className="fas fa-envelope"></i>
                <input type="email" name="correo" autoComplete="email" placeholder="Email" required="yes" value={email} onChange={handleEmail}/>
            </div>
            <div className="input-field">
                <i className="fas fa-lock"></i>
                <input type="password" name="contraseña" autoComplete="current-password" placeholder="Password" id="id_reg" required="yes" value={password} onChange={handlePassword}/>
                <i className="far fa-eye" id="toggleReg" style={{cursor: "pointer"}}></i>
            </div>
            
            <input type="submit" value="Sign Up" className="btn solid" onClick={handleRegister}/>
            
            </form>
        
    )
}

export default Register


