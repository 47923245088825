import { useEffect } from 'react'
import './upload.css'
import { fileUpload, FILE_CONTENT } from './uploadData'
import { useState } from 'react';
// import axios from 'axios';
import { baseURL } from '../../baseUrl';
import './custom.css'
import Header from '../header/header';
import Swal from 'sweetalert2'

import { useNavigate } from 'react-router-dom';


function Upload() {

  const navigate = useNavigate();

  const [patientCode, setPatient] = useState('');
  const [day, setDay] = useState('');
  const [chip, setChip] = useState('');
  const [invalidUpload, setinvalidUpload] = useState(false);
  const [backendMessage, setbackendMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const uploadUrl = `${baseURL}/pipeline/upload`
  let statCode
  let formData

  function handlePatientCode(e){
    setPatient(e.target.value)
}
function handleDay(e){
  setDay(e.target.value)
}

function handleChip(e){
  setChip(e.target.value)
}

function contentUpload(payload){
  console.log('here')
  setSubmitting(true)
  formData = new FormData();
  formData.append("file", payload.FILE_CONTENT);
  formData.append("patient_number", payload.patientCode);
  formData.append("day", payload.day);
  formData.append("chip", payload.chip);
  
  fetch(uploadUrl, {
  method: 'POST',
  body: formData
}).then(response => {
  console.log(response.status)
  statCode = response.status
  return response.json()
}).then(result => {
    setSubmitting(false)
    console.log(statCode)
  console.log('Success:', result);
  if(statCode == 409){
    uploadAlert()
    localStorage.setItem('image_exist', JSON.stringify(result))

  }else if(statCode == 201){
    analyseAlert()
    localStorage.setItem('analyse_data', JSON.stringify(result))
  }else if(statCode == 400){
    console.log(result.error)
    setinvalidUpload(true)
    setbackendMessage(result.error)
  }
}).catch(error =>{
  console.log(error)
  setSubmitting(false)
})
}

function handleUpload(e) {
    e.preventDefault();
    let payload = {
      patientCode,
      chip,
      day,
      FILE_CONTENT
    }
    contentUpload(payload)
    console.log(payload)
    resetUploadForm()
}
function resetUploadForm(){
  setPatient('')
  setDay('')
  setChip('')
}


  function goToAnalysePage() {
    navigate('/analyse');
  }

  useEffect(()=>{
    fileUpload()
}, []) 

  const analyseAlert = () => {
    Swal.fire({
      title: 'Upload Successful',
      icon: 'success',
      showConfirmButton: false,
      timer: 1000
    }).then((result) => {
      goToAnalysePage()
    })
  }
  const uploadAlert = () => {
    Swal.fire({
      title: 'Chip has already been analysed',
      text: 'Do you want to view the results?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      navigate('/analyse')
    })

    
}
  return (
    <div>

      <Header/>
     

        <div className='full-body'>
          {/* <button className='btn btn-primary' onClick={analyseAlert}>Success Alert</button> */}
          {/* CHANGE THIS TO FORM TAG */}
          <div className="form-area-stuff"> 
            <div style={{flex:'1', width:'80%', margin:'auto'}}>    
              <h3>Patient Details</h3>    
              {invalidUpload?<p className="text-danger">{backendMessage}</p> :''}
              <div className="form-grou" style={{display:'flex', flexDirection:'column'}}>
                <input value={patientCode} onChange={handlePatientCode} type="patientCode" className="form-contro input-field" id="exampleInputEmail1" aria-describedby="patientHelp" placeholder="Patient code"/>
              
                {/* <input value={day} onChange={handleDay} type="day" className="form-contro input-field field-middle" id="exampleInputDay" placeholder="Day"/> */}
                <select className="form-selec input-field" aria-label="Default select example" value={day} onChange={handleDay}>
                  <option>Select Day</option>
                  <option value="Day0">Day0</option>
                  <option value="Day1">Day1</option>
                  <option value="Day2">Day2</option>
                  <option value="Day3">Day3</option>
                </select>
                {/* <input value={chip} onChange={handleChip} type="chip" className="form-contro input-field" id="exampleInputChip" placeholder="Chip"/> */}
                <select className="form-selec input-field" aria-label="Default select example" value={chip} onChange={handleChip}>
                <option>Select Chip</option>
                  <option value="Chip1A">Chip1A</option>
                  <option value="Chip1B">Chip1B</option>
                  <option value="Chip2A">Chip2A</option>
                  <option value="Chip2B">Chip2B</option>
                  <option value="Chip3A">Chip3A</option>
                  <option value="Chip3B">Chip3B</option>
                  <option value="Chip4A">Chip4A</option>
                  <option value="Chip4B">Chip4B</option>
                  <option value="Chip5A">Chip5A</option>
                  <option value="Chip5B">Chip5B</option>
                  <option value="Chip6A">Chip6A</option> 
                  <option value="Chip6B">Chip6B</option>
                </select>
              </div>
            </div>

            {/* <!----> */}

            <div style={{flex:'1', width:'100%'}}>   
              <div id="uploadArea" className="upload-area">
                <div className="upload-area__header">
                  <p className="upload-area__paragraph">
                    <strong className="upload-area__tooltip">
                      <span className="upload-area__tooltip-data"></span>
                    </strong>
                  </p>
                </div>

                <div id="dropZoon" className="upload-area__drop-zoon drop-zoon">
                  <span className="drop-zoon__icon">
                    <i className='bx bxs-file-image'></i>
                  </span>
                  <p className="drop-zoon__paragraph">Drop your file here or Click to browse</p>
                  <span id="loadingText" className="drop-zoon__loading-text">Please Wait</span>
                  <img src="" alt="Preview Image" id="previewImage" className="drop-zoon__preview-image" draggable="false"/>
                  <input type="file" id="fileInput" className="drop-zoon__file-input"/>
                </div>

                <div id="fileDetails" className="upload-area__file-details file-details">
                  <h3 className="file-details__title">Uploaded File</h3>

                  <div id="uploadedFile" className="uploaded-file">
                    <div className="uploaded-file__icon-container">
                      <i className='bx bxs-file-blank uploaded-file__icon'></i>
                      <span className="uploaded-file__icon-text"></span> 
                    </div>

                    <div id="uploadedFileInfo" className="uploaded-file__info">
                      <span className="uploaded-file__name">Proejct 1</span>
                      <span className="uploaded-file__counter">0%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div style={{display:'flex', justifyContent:'center', marginTop:'2rem'}}>
            <button style={{marginRight:'10px'}} type="submit" className="btn btn-danger" onClick={handleUpload}>
              {submitting? <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> : ""}
              
              Submit
              </button>
            {/* <button onClick={goToAnalysePage} className="btn btn-analyse">Analyse Page</button> */}
          </div>

        </div>   

    </div>

    
  )
  }

export default Upload
