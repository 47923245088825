import "./analyse.css";
import Header from "../header/header";
import { useEffect, useState } from "react";
import { baseURL } from "../../baseUrl";
import axios, { Axios } from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function Analyse() {
  const navigate = useNavigate();
  const [hasAnalysed, setHasAnalysed] = useState(false);
  const [responseFromBackend, setResponseFromBackend] = useState(null);
  const [invalidAnalysis, setinvalidAnalysis] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [backendMessage, setbackendMessage] = useState("");
  const analyseUrl = `${baseURL}/pipeline/newpipeline`;
  // const analyseUrl = `${baseURL}/pipeline/pipeline_latest`;
  const removeUrl = `${baseURL}/pipeline/remove`;
  let statCode;
  let rightNow = new Date();
  rightNow = rightNow.toLocaleString("en-GB", { timeZone: "Europe/London" });

  let logged_in_username = JSON.parse(localStorage.getItem("user_details"));
  function viewImageData() {
    if (JSON.parse(localStorage.getItem("image_exist"))) {
      setResponseFromBackend(JSON.parse(localStorage.getItem("image_exist")));
      setHasAnalysed(true);
    }
  }
  function analyseContent(payload) {
    setSubmitting(true);
    setHasAnalysed(false);
    let formData = new FormData();
    formData.append("patient_number", payload.patient_code);
    formData.append("day", payload.day);
    formData.append("chip", payload.chip);
    axios
      .post(analyseUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
        setSubmitting(false);
        setResponseFromBackend(response.data);
        setHasAnalysed(true);
        remove_data(response.data.patient_code);
      })
      .catch((error) => {
        // console.log(error.response.data.error)
        console.log(error);
        setinvalidAnalysis(true);
      });
  }

  function analyseContentTest(payload) {
    setSubmitting(true);
    setHasAnalysed(false);
    let formData = new FormData();
    formData.append("patient_number", payload.patient_code);
    formData.append("day", payload.day);
    formData.append("chip", payload.chip);
    fetch(analyseUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        console.log(response.status);
        statCode = response.status;
        return response.json();
      })
      .then((result) => {
        setSubmitting(false);
        console.log(statCode);
        console.log("Success:", result);
        if (statCode == 200) {
          setSubmitting(false);
          setResponseFromBackend(result);
          setHasAnalysed(true);
          remove_data(result.patient_code);
        } else if (statCode == 400 || statCode == 404) {
          console.log(result.error);
          setSubmitting(false);
          analyseErrorAlert(payload.patient_code);
        } else {
          setSubmitting(false);
          analyseErrorAlert();
        }
      });
  }

  const analyseErrorAlert = (_patientData) => {
    Swal.fire({
      title: "Analysis Stopped",
      text: "The zip folder uploaded was corrupt, try again",
      icon: "error",
    }).then((result) => {
      remove_data(_patientData);
      navigate("/upload");
    });
  };

  function handleAnalysis(e) {
    e.preventDefault();
    let payload = JSON.parse(localStorage.getItem("analyse_data"));
    console.log(payload);
    // analyseContent(payload)
    analyseContentTest(payload);
  }

  function remove_data(patient_code) {
    axios.get(`${removeUrl}/${patient_code}`).then((response) => {
      console.log(response.data);
    });
  }

  function clear_storage() {
    window.localStorage.removeItem("image_exist");
    window.localStorage.removeItem("analyse_data");
    setHasAnalysed(false);
  }

  useEffect(() => {
    console.log("we deyyyyyyyyy");
    viewImageData();
    return () => {
      console.log("we dey");
      clear_storage();
      console.log("we no dey");
    };
  }, []);

  return (
    <div>
      <Header />

      <div>
        {hasAnalysed ? (
          <div style={{ marginTop: "1rem", padding: "4rem" }}>
            <p>User: {logged_in_username}</p>
            <p>Time: {rightNow}</p>
            <div className="text-center mb-4">
              <img src={responseFromBackend.url} height="500px" />
            </div>

            <div className="row">
              <div className="col-sm-3">
                <div className="car">
                  <div className="containerr">
                    <div className="cardd">
                      <div className="cardd-img">
                        <h2 className="text-center">Patient Code</h2>
                      </div>
                      <div className="cardd-content">
                        <h3 className="big-title">
                          {responseFromBackend.patient_code}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="car">
                  <div className="containerr">
                    <div className="cardd">
                      <div className="cardd-img">
                        <h2 className="text-center">Day</h2>
                      </div>
                      <div className="cardd-content">
                        <h3 className="big-title">{responseFromBackend.day}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-sm-3">
                <div className="car">
                  <div className="containerr">
                    <div className="cardd">
                      <div className="cardd-img">
                        <h2 className="text-center">User</h2>
                      </div>
                      <div className="cardd-content">
                        <h3 className="big-title">
                          Username: {logged_in_username}
                        </h3>
                        <h3 className="big-title">Time: {rightNow}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="col-sm-3">
                <div className="car">
                  <div className="containerr">
                    <div className="cardd">
                      <div className="cardd-img">
                        <h2 className="text-center">Chip</h2>
                      </div>
                      <div className="cardd-content">
                        <h3 className="big-title">
                          {responseFromBackend.chip}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="car">
                  <div className="containerr">
                    <div className="cardd">
                      <div className="cardd-img">
                        <h2 className="text-center">Cell Count</h2>
                      </div>
                      <div className="cardd-content">
                        <h3 className="big-title">
                          Ch01: {responseFromBackend.live_cell_count}
                        </h3>
                        <h3 className="big-title">
                          Ch00: {responseFromBackend.dead_cell_count}
                        </h3>
                        <h3 className="big-title">
                          Dual: {responseFromBackend.dual_cell_count}
                        </h3>
                        {/* <h3 className="medium-title">Dead:{responseFromBackend.dead_cell_count}</h3> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="btn-analyse-div">
            {invalidAnalysis ? (
              <p className="text-danger">{backendMessage}</p>
            ) : (
              ""
            )}
            <button className="btn-analyse" onClick={handleAnalysis}>
              {submitting ? (
                <span
                  className="spinner-grow spinner-grow-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                ""
              )}
              Click to Analyse
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Analyse;
