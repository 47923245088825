import logo from './logo.svg';
import './App.css';
import Login from './components/login'
// import { Router } from 'react-router-dom';
import {BrowserRouter as Router,Switch,Route,Link} from "react-router-dom";

function App() {
  return (
    // <Router>
    //     <Switch>
    //       <Route path="/upload">
    //         <Upload />
    //       </Route>
    //       <Route path="/">
    //         <Login />
    //       </Route>
    //     </Switch>
    // </Router>
    <div className="App">
      <Login />
    </div>
  );
}


export default App;
