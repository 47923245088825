import '../styles/login.css'
import { useState } from 'react';
import Register from './register';
import axios from 'axios';
import {baseURL} from '../baseUrl';

import Swal from 'sweetalert2'

import { useNavigate } from 'react-router-dom';

function Login() {
    const navigate = useNavigate();
    const loginUrl = `${baseURL}/auth/login`
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [invalidLogin, setinvalidLogin] = useState(false);
    const [backendMessage, setbackendMessage] = useState('');
    let statCode

    function navRegister(){
        const container = document.querySelector(".container");
        container.classList.add("sign-up-mode");
    }
    function navLogin(){
        const container = document.querySelector(".container");
        container.classList.remove("sign-up-mode");
    }

    function handleUsername(e){
        setUsername(e.target.value)
    }
    function handlePassword(e){
        setPassword(e.target.value)
    }

    function handleLogin(e) {
        e.preventDefault();
        let payload = {
            username,
            password,
        }
        console.log(payload)
        signIn(payload)
        resetLoginForm()
    }
    function resetLoginForm(){
        setUsername('')
        setPassword('')
    }

    function signIn(payload){
        setbackendMessage('')
        setinvalidLogin(false)
        let formData = new FormData();
        formData.append("username", payload.username);
        formData.append("password", payload.password);

        fetch(loginUrl, {
        method: 'POST',
        body: formData
      }).then(response => {
        console.log(response)
        statCode = response.status
        return response.json()
      }).then(result => {
        console.log('success:', result);
        console.log(statCode)
        if(statCode == 401 || statCode == 400){
            console.log(result.error)
            setinvalidLogin(true)
            setbackendMessage(result.error)
        }else if(statCode == 200){
            localStorage.setItem('user_details', JSON.stringify(result.user.username))
            loginAlert(result.user.username)
        }else{
            setinvalidLogin(true)
            setbackendMessage('Something went wrong, refresh and try again')
        }
      })
      }


      const loginAlert = (username) => {
        Swal.fire({
            title: `Welcome back! ${username}`,  
            text: 'Your login was succcessful.',
            icon: 'success',
            showConfirmButton: false,
            timer: 1000
        }).then((result) => {
            navigate('/upload')
          })
    }



    return (
      <div>

        <div className="container">

            <div className="forms-container">

           

            <div className="signin-signup">
            <div className="login-logo">
                <img height='100px' src="/logo-pearbio.png" alt="logo"/> 
            </div>
                <form action="" className="sign-in-form">
                    <h2 className="title">Login</h2>
                    {invalidLogin?<p className="text-danger">{backendMessage}</p> :''}
                    <div className="input-field">
                        <i className="fas fa-user"></i>
                        <input type="text" name="usuario" autoComplete="username" placeholder="Username" required="yes" value={username} onChange={handleUsername}/>
                    </div>
                    <div className="input-field">
                        <i className="fas fa-lock"></i>
                        <input type="password" name="contraseña" autoComplete="current-password" placeholder="Password" id="id_password" required="yes" value={password} onChange={handlePassword}/>
                        <i className="far fa-eye" id="togglePassword" style={{cursor: "pointer"}}></i>
                    </div>

                    <input type="submit" value="Sign in" className="btn solid" onClick={handleLogin}/>

                </form>
                <Register />
            </div>
            </div>
            <div className="panels-container">
            <div className="panel left-panel">
                <div className="content">
                <h3>You don't have an account?</h3>
                <p>Create your account</p>
                <button className="btn transparent" id="sign-up-btn" onClick={navRegister}>Register</button>
                </div>
                <div className="image"></div>
            </div>

            <div className="panel right-panel">
                <div className="content">
                <h3>Already have an account?</h3>
                <p>Login to analyse</p>
                <button className="btn transparent" id="sign-in-btn" onClick={navLogin}>Sign in</button>
                </div>
                <div className="image"></div>
            </div>
            </div>
        </div>

      </div>
    );
  }

export default Login