import { Outlet } from "react-router-dom";
import Login from "./components/login";

const useAuth = () =>{
    // const user = {loggedIn: false}
    let user_details = localStorage.getItem('user_details')
    console.log(user_details)
    return user_details ? true : false;
}

const ProtectedRoutes = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet /> : <Login/>
}

export default ProtectedRoutes;